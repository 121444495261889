var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"team-management-table",attrs:{"loading":_vm.$store.getters['teamManagement/loading'],"headers":_vm.headers,"items":_vm.$store.getters['teamManagement/users'],"server-items-length":_vm.$store.getters['teamManagement/totalUsers'],"items-per-page":_vm.params.per_page,"page":_vm.params.page,"single-select":true,"item-key":"id","flat":"","disable-sort":""},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.params, "per_page", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.params, "per_page", $event)},function($event){return _vm.fetchData()}],"update:page":[function($event){return _vm.$set(_vm.params, "page", $event)},function($event){return _vm.fetchData()}]},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [(!_vm.isOwner(item))?_c('v-simple-checkbox',{directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],attrs:{"value":isSelected,"color":"primary"},on:{"input":function($event){return select($event)}}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!_vm.isOwner(item))?_c('TeamManagementDeleteUser',{attrs:{"user":item}}):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"MMM DD, HH:mm"))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.status))+" ")]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }